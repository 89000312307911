import { Player } from '@lottiefiles/react-lottie-player';

export default function JSONIcon(props:any) { 
  const {
    height = '25vh',
    width = '25vw',
  } = props;

  return <Player
            autoplay
            loop
            src={`/img/json/${props.src}`}
            style={{ height, width, }}
          >
          </Player>
};