import { useState } from "react";
import { Row, Col, Drawer } from "antd";
import { withTranslation } from "react-i18next";
import Container from "../../common/Container";
import { SvgIcon } from "../../common/SvgIcon";
import { Button } from "../../common/Button";
import MultipleFormatImage from 'common/MultipleFormatImage';
import LinkTo from 'common/LinkTo';
import ScrollTo from 'common/ScrollTo';

import {
  HeaderSection,
  LogoContainer,
  Burger,
  NotHidden,
  Menu,
  CustomNavLinkSmall,
  Label,
  Outline,
  Span,
} from "./styles";

interface SocialLinkProps {
  href: string;
  src: string;
}
const SocialLink = ({ href, src }: SocialLinkProps) => {
  return (
    <a
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      key={src}
      aria-label={src}
    >
      <MultipleFormatImage src={src} width="25px" height="25px" />
    </a>
  );
};

const Header = ({ t }: any) => {
  const [visible, setVisibility] = useState(false);

  const showDrawer = () => {
    setVisibility(!visible);
  };

  const onClose = () => {
    setVisibility(!visible);
  };

  const MenuItem = () => {
    return (
      <>
        <CustomNavLinkSmall>
          <LinkTo href="/home">{t("Home")}</LinkTo>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall>
          <LinkTo href="/contact">{t("Contact")}</LinkTo>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall>
          <SocialLink
            href="https://www.youtube.com/channel/UCfdtp-mKCXxdABzDLL4Qtew"
            src="youtube.svg"
          />
        </CustomNavLinkSmall>
        <CustomNavLinkSmall>
          <SocialLink
            href="https://www.linkedin.com/company/ms3biz"
            src="linkedin.svg"
          />
        </CustomNavLinkSmall>
        <CustomNavLinkSmall>
          <SocialLink
            href="https://www.patreon.com/user?u=71622561&fan_landing=true"
            src="patreon.svg"
          />
        </CustomNavLinkSmall>
        <CustomNavLinkSmall>
          <SocialLink
            href="https://medium.com/@ms3biz"
            src="medium.svg"
          />
        </CustomNavLinkSmall>
        <CustomNavLinkSmall>
          <a href="https://www.buymeacoffee.com/ms3biz"
             target="_blank"
             rel="noopener noreferrer"
             aria-label="Buy MS3 a coffee"
          >
            <img
              src="https://img.buymeacoffee.com/button-api/?text=Buy MS3 a coffee&slug=ms3biz&button_colour=e80000&font_colour=ffffff&font_family=Lato&outline_colour=000000&coffee_colour=FFDD00"
              alt="Buy MS3 a coffee"
            />
          </a>
        </CustomNavLinkSmall>
      </>
    );
  };

  return (
    <HeaderSection>
      <Container>
        <Row justify="space-between">
          <LogoContainer to="/" aria-label="homepage">
            <MultipleFormatImage src="short_logo.png"
                                 width="150vw" height="auto" />
          </LogoContainer>
          <NotHidden>
            <MenuItem />
          </NotHidden>
          <Burger onClick={showDrawer}>
            <Outline />
          </Burger>
        </Row>
        <Drawer closable={false} visible={visible} onClose={onClose}>
          <Col style={{ marginBottom: "2.5rem" }}>
            <Label onClick={onClose}>
              <Col span={12}>
                <Menu>Menu</Menu>
              </Col>
              <Col span={12}>
                <Outline />
              </Col>
            </Label>
          </Col>
          <MenuItem />
        </Drawer>
      </Container>
    </HeaderSection>
  );
};

export default withTranslation()(Header);
