import { Link } from "react-router-dom";

export default function LinkTo(props: any) {
  const {
    href,
    children,
    target = "_parent",
  } = props;

  return (
    <Link to={href} target={target} underline="hover">
      {children}
    </Link>
  );
}