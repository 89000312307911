const routes = [
  {
    path: ["/", "/home"],
    exact: true,
    component: "Home",
  },
  {
    path: ["/quiz-composer",],
    exact: true,
    component: "QuizComposer",
  },
  {
    path: ["/under-the-radar",],
    exact: true,
    component: "UnderTheRadar",
  },
  {
    path: ["/prepared",],
    exact: true,
    component: "Prepared",
  },
  {
    path: ["/contact",],
    exact: true,
    component: "Contact",
  }
  ,
  {
    path: ["/blackambitionprize",],
    exact: true,
    component: "BlackAmbitionPrize",
  }
];

export default routes;
