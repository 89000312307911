import { MultiFormatImageProps } from "common/types";
import { SvgIcon } from "common/SvgIcon";
import JSONIcon from './JSONIcon';
import PngIcon from './PngIcon';
import JpegIcon from './JpegIcon';

const ImageFormat = {
  'json': JSONIcon,
  'svg': SvgIcon,
  'png': PngIcon,
  'jpeg': JpegIcon,
  'jpg': JpegIcon,
}

export default function MultipleFormatImage(props:MultiFormatImageProps) {
  const format = props.src.split('.')[1];
  const Image = ImageFormat[format];
  return <Image {...props} />
}
